<template>
    <div>
        <v-app>
            <div class="row">
                <div class="col">
                    <v-card>
                        <v-card-title>
                            Ordenes de compra
                            <v-spacer></v-spacer>
                            <v-btn class="" @click="fetchDataPurchaseOrders"  text icon color="blue">
                                <v-icon>mdi-refresh</v-icon>
                            </v-btn>
                        </v-card-title>
                        <v-card-text>
                            <h3 class="title">Seleccione una orden de compra para envasar:</h3>
                            <div class="datepicker-trigger">
                                <v-text-field
                                    id="datepicker-trigger"
                                    placeholder="Filtrar por rango de fechas"
                                    :value="formatDates(dateOne, dateTwo)"
                                />
                                <AirbnbStyleDatepicker
                                    :trigger-element-id="'datepicker-trigger'"
                                    :mode="'range'"
                                    :fullscreen-mobile="true"
                                    :date-one="dateOne"
                                    :date-two="dateTwo"
                                    @date-one-selected="val => { dateOne = val }"
                                    @date-two-selected="val => { dateTwo = val }"
                                />
                            </div>
                            <v-data-table
                                :headers="headers"
                                :items="tableData"
                                :search="search"
                                :single-select="true"
                                show-select
                                v-model="selected"
                                no-results-text="No hay resultados disponibles"
                                no-data-text="No hay datos disponibles"
                                loading-text="Cargando datos..."
                                :sort-by="'id'"
                                :sort-desc="false"
                                class="elevation-1 datatable"
                            >
                                <template v-slot:item.ingreso="{ item }">
                                    <td class="text-center">
                                        {{ $moment(item.created_at).format('MMMM Do YYYY, h:mm:ss a') }}
                                    </td>
                                </template>
                                <template v-slot:item.acciones="{ item }">
                                    <td class="text-center">
                                        <!--
                                        <v-btn @click="downloadPdf(item.id)" small color="primary" dark 
                                        ><v-icon left dark>mdi-download</v-icon>Descargar PDF</v-btn>
                                        -->
                                        <v-btn v-if="item.hasPurchasedProducts" @click="downloadPdfCompras(item.id)" small color="success"  
                                        ><v-icon left dark>mdi-download</v-icon>PDF compras</v-btn>
                                    </td>
                                    
                                </template>
                                <template v-slot:item.estado="{ item }">
                                    <td class="text-center">
                                        <b-button size="sm" v-if="item.estado == 'Envasando' && item.purchase_order_details.every(e => e.number_products > 0)" pill variant="info">Envasando</b-button>
                                        <b-button size="sm" v-if="item.estado == 'Envasando' && !item.purchase_order_details.every(e => e.number_products > 0)" pill variant="info">Comprando</b-button>
                                        <b-button size="sm" v-if="item.estado == 'Envasado'" pill variant="success">Envasado</b-button>
                                    </td>
                                </template>
                            </v-data-table>
                            <br><br>
                            <div v-if="selected.length" class="ml-2">
                            <v-btn @click="startEnvasarItems" v-show="selected[0].estado != 'Envasado' && selected[0].purchase_order_details.every(e => e.number_products > 0)" small color="success" dark class="float-left"
                            ><v-icon left dark>mdi-format-list-checks</v-icon>Comenzar a envasar</v-btn>
                            </div>
                            <br><br>
                            <v-container v-show="tableDataOrderDetails2.length && mostrar == 2">
                                <h3>Detalle de ordenes de producción</h3>
                                <v-data-table
                                    :headers="headersOrderDetails2"
                                    :items="tableDataOrderDetails2"
                                    :search="searchOrderDetails2"
                                    v-model="selectedOrderDetails2"
                                    no-results-text="No hay resultados disponibles"
                                    no-data-text="No hay datos disponibles"
                                    loading-text="Cargando datos..."
                                    :sort-by="'producto'"
                                    :sort-desc="true"
                                    class="elevation-1 datatable"
                                >
                                    <template v-slot:item.cantidad="{ item }">
                                        <td class="text-center">
                                            {{item.cantidad}} {{item.listaComprados[0].listaMermados[0].unit.name}}
                                        </td>
                                    </template>
                                    <template v-slot:item.requerido="{ item }">
                                        <td class="text-center">
                                            {{item.requerido}} {{item.listaComprados[0].listaMermados[0].unit.name}}
                                        </td>
                                    </template>
                                    <template v-slot:item.estado="{ item }">
                                        <td class="text-center">
                                            <v-chip
                                            v-if="!item.listaComprados.every(e => e.listaEnvasados.every(y => parseFloat(y.envased) > 0.0 ) )"
                                                class="ma-2"
                                                color="orange"
                                                text-color="white"
                                                >
                                                Pendiente
                                            </v-chip>
                                            <v-chip
                                                v-if="!!item.listaComprados.every(e => e.listaEnvasados.every(y => parseFloat(y.envased) > 0.0 ) )"
                                                class="ma-2"
                                                color="green"
                                                text-color="white"
                                                >
                                                <v-avatar left>
                                                    <v-icon>mdi-checkbox-marked-circle</v-icon>
                                                </v-avatar>
                                                Envasados
                                            </v-chip>
                                            
                                        </td>
                                    </template>
                                    <template v-slot:item.accion="{ item }">
                                        <td class="text-center">
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on }">
                                                    <v-icon
                                                        small
                                                        class="mr-2"
                                                        v-on="on"
                                                        @click="seleccionProveedor(item)"
                                                    >
                                                        mdi-arrow-right-box
                                                    </v-icon>
                                                </template>
                                                <span>Ingresar envasados</span>
                                            </v-tooltip>
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on }">
                                                    <v-icon
                                                        small
                                                        class="mr-2"
                                                        v-on="on"
                                                        @click="seleccionProveedorMerma(item)"
                                                    >
                                                        mdi-delete-variant
                                                    </v-icon>
                                                </template>
                                                <span>Ingresar merma</span>
                                            </v-tooltip>
                                        </td>
                                    </template>
                                </v-data-table>
                                <br><br>
                                <v-btn @click="finishEnvasar2" v-show="tableDataOrderDetails2.every(e => e.listaComprados.every(y => y.estado == 'Envasados') )" small color="success" dark class="float-left"
                                ><v-icon left dark>mdi-format-list-checks</v-icon>Guardar</v-btn>
                                
                            </v-container>
                        </v-card-text>
                    </v-card>
                </div>
            </div>
            <v-dialog
                v-model="dialogProveedorMerma"
                max-width="500px"
            >
                <v-card>
                <v-card-title>
                    Proveedor
                </v-card-title>
                <v-card-text>
                    <v-combobox
                        :items="listaProveedores"
                        v-model="selectProveedor"
                        label="Selecciona un proveedor"
                        ref="vselect"
                    ></v-combobox>
                </v-card-text>
                <v-card-actions>
                    <v-btn
                    color="primary"
                    text
                    @click="dialogProveedor = false"
                    >
                    Cancelar
                    </v-btn>
                    <v-btn
                    color="success"
                    text
                    v-show="!!pivotItem && selectProveedor"
                    @click="toMermar(pivotItem)"
                    >
                    Continuar
                    </v-btn>
                </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog
                v-model="dialogProveedor"
                max-width="500px"
            >
                <v-card>
                <v-card-title>
                    Proveedor
                </v-card-title>
                <v-card-text>
                    <v-combobox
                        :items="listaProveedores"
                        v-model="selectProveedor"
                        label="Selecciona un proveedor"
                        ref="vselect"
                    ></v-combobox>
                </v-card-text>
                <v-card-actions>
                    <v-btn
                    color="primary"
                    text
                    @click="dialogProveedor = false"
                    >
                    Cancelar
                    </v-btn>
                    <v-btn
                    color="success"
                    text
                    v-show="!!pivotItem && selectProveedor"
                    @click="toEnvasar(pivotItem)"
                    >
                    Continuar
                    </v-btn>
                </v-card-actions>
                </v-card>
            </v-dialog>
        </v-app>
        <envasador-del-item @finish="recibirEnvasados" ></envasador-del-item>
        <mermador @finish="recibirMermados" ></mermador>
    </div>
</template>

<script>
import EnvasadorDelItem from '@/views/oms/Compras/EnvasadorDelItem.vue';
import Mermador from '@/views/oms/Compras/Mermador.vue';
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import format from 'date-fns/format';
export default {
    components: {
        EnvasadorDelItem,
        Mermador,
    },
    data(){
        return {
            dateFormat: 'D MMM',
            dateOne: '',
            dateTwo: '',
            range: {},
            headers: [
                {
                text: '#...',
                align: 'left',
                sortable: true,
                value: 'id',
                },
                { text: 'Referencia Orden de Compra', value: 'purchase_order_reference' },
                { text: 'Fecha de ingreso', value: 'ingreso', filter: this.filtroRangoFechas },
                //{ text: 'Merma', value: 'cantidad_mermada'},
                { text: 'Estado', value: 'estado' },
                { text: 'Acciones', value: 'acciones' },
            ],
            tableData: [],
            search: '',
            selected: [],
            itemsOrden: [],
            modelGroup: null,
            headersOrderDetails: [
                {
                text: '#...',
                align: 'left',
                sortable: true,
                value: 'id',
                },
                { text: 'Referencia Pedido', value: 'referencia' },
                { text: 'Producto', value: 'name'},
                { text: 'SKU', value: 'sku'},
                { text: 'Cantidad', value: 'quantity'},
                { text: 'Fecha de ingreso', value: 'ingreso' },
            ],
            tableDataOrderDetails: [],
            searchOrderDetails: '',
            selectedOrderDetails: [],

            headersOrderDetails2: [
                
                { text: 'SKU', value: 'sku' },
                { text: 'Producto', value: 'producto'},
                { text: 'Formato', value: 'formato'},
                { text: 'Cantidad requerida', value: 'requerido'},
                { text: 'Cantidad comprada', value: 'cantidad'},
                
                { text: 'Estado', value: 'estado'},
                { text: 'Accion', value: 'accion' },
            ],
            tableDataOrderDetails2: [],
            searchOrderDetails2: '',
            selectedOrderDetails2: [],

            mostrar: 0,

            tableDataProducts: [],
            searchProducts: '',
            selectedProducts: [],
            headersProducts: [
                {
                text: '#...',
                align: 'left',
                sortable: true,
                value: 'id',
                },
                { text: 'Item', value: 'nombre' },//
                { text: 'Proveedor', value: 'proveedor' },//
                { text: 'Cantidad disponible', value: 'cantidad' },
                //{ text: ''}
            ],
            detallesOrdenList: [],
            envasProducto: undefined,
            envasCantidad: 0,
            envasSku: '',
            envaUnit: {},
            date: new Date().toISOString().substr(0, 10),
            modal: false,
            headersEnvasados: [
                { text: 'Producto', value: 'producto' },//
                { text: 'Cantidad envasada', value: 'cantidad' },//
                { text: 'Sku generado', value: 'sku' },
                { text: 'Fecha vencimiento', value: 'vencimiento'}
            ],
            tableDataEnvasados: [],
            searchEnvasados: '',
            urlPdf: '',

            listaProveedores: [],
            allProveedores: [],
            dialogProveedor: false,
            dialogProveedorMerma: false,
            selectProveedor: undefined,
            pivotItem: undefined,
        }
    },
    methods: {
        fetchDataPurchaseOrders()
        {
            var vm = this;
            this.axios({
                method: 'POST',
                url: 'inventory/purchase_orders',
                data: {
                    store: this.$store.state.passport.user.default_store_id
                }
            }).then( response => {
                vm.tableData = response.data;
                
            }).catch( error => {
                console.log(error);
            });
        },
        downloadPdf(id)
        {
            this.axios({
                url: 'inventory/purchase_order/'+id+'/pod_pdf',
                method: 'GET'
            }).then( response => {
                window.open(response.data, "_blank");
            }).catch( error => {
                console.log(error);
            });
        },
        filtroRangoFechas(value, search, item){
            console.log('item', item);
            console.log('value', value);
            if( !this.dateOne || !this.dateTwo ){
                return true;
            }else{
                if( this.$moment(item.created_at).isSameOrAfter(this.$moment(this.dateOne)) && this.$moment(item.created_at).isSameOrBefore(this.$moment(this.dateTwo)) ){
                    return true;
                }else{
                    return false;
                }
            }
        },
        formatDates(dateOne, dateTwo) {
            let formattedDates = ''
            if (dateOne) {
                formattedDates = format(dateOne, this.dateFormat)
            }
            if (dateTwo) {
                formattedDates += ' - ' + format(dateTwo, this.dateFormat)
            }
            return formattedDates
        },
        
        startEnvasarItems()
        {
            var vm = this;
            this.axios({
                url: 'inventory/order_details_group_by_sku',
                method: 'POST',
                data: {
                    purchase_order: this.selected[0].id
                }
            }).then( response => {
                //vm.tableDataOrderDetails = response.data;
                //vm.detallesOrdenList = response.data.map(e => ({text: e.name, value: e.id, unit: e.unit}));
                /*
                vm.tableDataOrderDetails2 = [];
                Object.keys(response.data).forEach( e => {
                    let sumatoriaCantidad = 0;
                    response.data[e].forEach(element => {
                        sumatoriaCantidad+= element.quantity;
                    });
                    let listaEnvasados = [];
                    response.data[e].forEach( elem => {
                        for(let i=0; i<elem.quantity; i++)
                        {
                            listaEnvasados.push({
                                required: '1 '+elem.unit.name,
                                envased: parseFloat(0),
                                generated_sku: '',
                                expires_at: new Date().toISOString().substr(0, 10),
                                id: elem.id,
                                unit: elem.unit
                            });
                        }
                    });
                    vm.tableDataOrderDetails2.push({
                        sku: e,
                        producto: response.data[e][0].name,
                        cantidad: sumatoriaCantidad+' '+response.data[e][0].unit.name,
                        estado: 'Pendiente',
                        order_details: response.data[e],
                        listaEnvasados
                    });
                });
                */
                vm.tableDataOrderDetails2 = [];
                Object.keys(response.data).forEach( sku => {
                    let nombre_producto = response.data[sku][Object.keys(response.data[sku])[0]][0].purchase_order_detail.name;
                    let cantidad = 0.0;
                    Object.keys(response.data[sku]).forEach( proveedor => {
                        response.data[sku][proveedor].forEach( purchase_product => {
                            cantidad+= parseFloat(purchase_product.quantity);
                        })
                    });
                    let listaComprados = [];
                    let formato = '';
                    let cantidad_requerida = 0.0;
                    Object.keys(response.data[sku]).forEach( proveedor => {
                        let objProveedor = {name: proveedor};
                        
                        let cantidadPorProveedor = 0.0;
                        response.data[sku][proveedor].forEach( purchase_product => {
                            cantidadPorProveedor+= parseFloat(purchase_product.quantity);
                        });
                        objProveedor.cantidad_comprada = cantidadPorProveedor;
                        objProveedor.listaEnvasados = [];
                        objProveedor.listaMermados = [];
                        objProveedor.listaMermados.push({
                                mermed: parseFloat(0),
                                comment: '',
                                expires_at: new Date().toISOString().substr(0, 10),
                                id: response.data[sku][proveedor][0].purchase_order_detail.order_detail_correspondiente.id,
                                unit: response.data[sku][proveedor][0].unit
                            });
                        console.log('dividiendo: ', cantidadPorProveedor, '/', parseInt(parseFloat(response.data[sku][proveedor][0].purchase_order_detail.sku_unit.quantity)));
                        let incrementador = parseFloat(response.data[sku][proveedor][0].purchase_order_detail.sku_unit.quantity);
                        let idref = 0;
                        for(let i=0.0; i< cantidadPorProveedor; i+=incrementador)
                        {
                            let ms = new Date().getTime() + (86400000 *5 );
                            let fiveDays = new Date(ms).toISOString().substr(0, 10);
                            objProveedor.listaEnvasados.push({
                                required: response.data[sku][proveedor][0].purchase_order_detail.sku_unit.quantity+' '+response.data[sku][proveedor][0].unit.name,
                                envased: parseFloat(0),
                                generated_sku: '',
                                expires_at: fiveDays,
                                id: response.data[sku][proveedor][0].purchase_order_detail.order_detail_correspondiente.id,
                                unit: response.data[sku][proveedor][0].unit,
                                idref: idref
                            });
                            idref++;
                        }
                        objProveedor.estado = 'Pendiente';
                        listaComprados.push(objProveedor);
                        formato = parseFloat(response.data[sku][proveedor][0].purchase_order_detail.sku_unit.quantity).toFixed(2)+' '+response.data[sku][proveedor][0].unit.name;
                        cantidad_requerida+= cantidadPorProveedor;
                    });
                    vm.tableDataOrderDetails2.push({
                        sku,
                        producto: nombre_producto,
                        cantidad,
                        estado: 'Pendiente',
                        order_details: response.data[sku],
                        listaComprados,
                        formato,
                        requerido: cantidad_requerida.toFixed(2)
                    });
                });
                vm.mostrar = 2;
            }).catch( error => {
                console.log(error);
                vm.tableDataOrderDetails2 = [];
            });
            /*
            this.axios({
                url: 'inventory/order_details_by_puchase_order',
                method: 'POST',
                data: {
                    purchase_order: this.selected[0].id
                }
            }).then( response => {
                vm.tableDataOrderDetails = response.data;
                vm.detallesOrdenList = response.data.map(e => ({text: e.name, value: e.id, unit: e.unit}));
                vm.mostrar = 2;
            }).catch( error => {
                console.log(error);
                vm.tableDataOrderDetails = [];
            });
            */
            this.axios({
                url: 'inventory/purchase_order_products_by_purchase_order',
                method: 'POST',
                data: {
                    purchase_order: this.selected[0].id
                }
            }).then( response => {
                console.log('respuesta: ', response.data);
                vm.tableDataProducts = response.data;
                let proveedores = {};
                response.data.forEach( prod => {
                    proveedores[prod.supplier.name] = prod.supplier.id;
                });
                console.log('proveedores (objetos)', proveedores);
                vm.listaProveedores = [];
                Object.keys(proveedores).forEach( atributo => {
                    vm.allProveedores.push({text: atributo, value: proveedores[atributo]});
                    vm.listaProveedores.push({text: atributo, value: proveedores[atributo]});
                });
                console.log('lista proveedores', vm.listaProveedores);
            }).catch( error => {
                console.log(error);
                vm.tableDataProducts = [];
            });
        },
        finishIngresarItems()
        {
            var vm = this;
            this.axios({
                url: 'inventory/finish_order_purchase',
                method: 'POST',
                data: {
                    items: this.itemsOrden
                }
            }).then( response => {
                vm.itemsOrden = [];
                vm.selected = [];
                vm.fetchDataPurchaseOrders();
            }).catch( error => {
                console.log(error);
            });
        },
        meTocaste(item)
        {
            console.log('ha sido tocado: ',item);
            const index = this.itemsOrden.indexOf(item)
            console.log({item, index});
            this.$emit('openDialogCompletadoDelItem', {item, index});
        },
        seleccionProveedor(item)
        {
            this.pivotItem = item;
            this.listaProveedores = [];
            Object.keys(item.order_details).forEach( proveedor => {
                this.listaProveedores.push( this.allProveedores.find( ap => ap.text == proveedor) );
            });
            this.selectProveedor = undefined;
            this.dialogProveedor = true;
        },
        seleccionProveedorMerma(item)
        {
            this.pivotItem = item;
            this.listaProveedores = [];
            Object.keys(item.order_details).forEach( proveedor => {
                this.listaProveedores.push( this.allProveedores.find( ap => ap.text == proveedor) );
            });
            this.selectProveedor = undefined;
            this.dialogProveedorMerma = true;
        },
        toEnvasar(item)
        {
            this.dialogProveedor = false;
            let indexProveedor =  item.listaComprados.indexOf(item.listaComprados.find(e => e.name == this.$refs.vselect.selectedItems[0].text));
            this.$emit('openDialogEnvasadorDelItem', { 
                title: item.producto, 
                listaEnvasados: item.listaComprados[indexProveedor].listaEnvasados, 
                indexProveedor,
                cantidadComprada: parseFloat(item.listaComprados[indexProveedor].cantidad_comprada),
                indexSku: this.tableDataOrderDetails2.indexOf(item), 
                supplier_id: this.selectProveedor.value, 
                supplier_name: this.$refs.vselect.selectedItems[0].text 
            });
        },
        toMermar(item)
        {
            this.dialogProveedorMerma = false;
            let indexProveedor =  item.listaComprados.indexOf(item.listaComprados.find(e => e.name == this.$refs.vselect.selectedItems[0].text));
            this.$emit('openDialogMermador', { 
                title: 'Merma de '+item.producto, 
                listaMermados: item.listaComprados[indexProveedor].listaMermados, 
                indexProveedor,
                //cantidadComprada: parseFloat(item.listaComprados[indexProveedor].cantidad_comprada),
                indexSku: this.tableDataOrderDetails2.indexOf(item), 
                supplier_id: this.selectProveedor.value, 
                supplier_name: this.$refs.vselect.selectedItems[0].text 
            });
        },
        recibirCanasta(data)
        {
            console.log('recibi: ', data);
            // Actualizando data del item desde el componente hijo dialog
            this.itemsOrden[data.index].purchased_quantity = parseFloat(data.total_quantity);
            this.itemsOrden[data.index].left = this.itemsOrden[data.index].required_quantity - data.total_quantity;
            this.itemsOrden[data.index].basket = data.basket;
        },
        recibirEnvasados(data)
        {
            this.tableDataOrderDetails2[data.indexSku].listaComprados[data.indexProveedor].listaEnvasados = data.listaEnvasados;
            if(data.listaEnvasados.some(e => e.envased > 0.0)){
                this.tableDataOrderDetails2[data.indexSku].listaComprados[data.indexProveedor].estado = 'Envasados';
            }else{
                this.tableDataOrderDetails2[data.indexSku].listaComprados[data.indexProveedor].estado = 'Pendiente';
            }
            
        },
        recibirMermados(data)
        {
            this.tableDataOrderDetails2[data.indexSku].listaComprados[data.indexProveedor].listaMermados = data.listaMermados;
        },
        cambiarEnvaUnit()
        {
            this.envaUnit = this.detallesOrdenList.find(e => e.value == this.envasProducto).unit;
        },
        downloadPdfCompras(id)
        {
            this.axios({
                url: 'inventory/purchase_order/'+id+'/pop_pdf',
                method: 'GET'
            }).then( response => {
                window.open(response.data, "_blank");
            }).catch( error => {
                console.log(error);
            });
        },
        agregarFila()
        {
            console.log('producto seleccionado: ', this.$refs.vselect.selectedItems[0]);
            this.tableDataEnvasados.push({
                producto: {name: this.$refs.vselect.selectedItems[0].text, id: this.envasProducto},
                cantidad: this.envasCantidad,
                sku: this.envasSku,
                vencimiento: this.date,
                unidad: this.detallesOrdenList.find(e => e.value == this.envasProducto).unit
            });
            this.envasProducto = undefined;
            this.envasCantidad = 0;
            this.envasSku = '';
            this.envaUnit = {};
            let ms = new Date().getTime() + (86400000 *5 );
            let fiveDays = new Date(ms).toISOString().substr(0, 10);
            this.vencimiento = fiveDays;
        },
        finishEnvasar()
        {
            console.log('finishEnvasar');
            var vm = this;
            this.axios({
                url: 'inventory/packaged_products',
                method: 'POST',
                data: { packaged_products: this.tableDataEnvasados},
            }).then( response => {
                vm.$bvToast.toast('Se han creado exitosamente '+this.tableDataEnvasados.length+' productos envasados', {
                    title: `Notificación`,
                    variant: 'success',
                    solid: true,
                    toaster: 'b-toaster-bottom-center'
                });
                vm.mostrar = 0;
            }).catch( error => {
                console.log(error);
            });
        },
        finishEnvasar2()
        {
            console.log('finishEnvasar2');
            let packaged_products = [];
            let mermed_products = [];
            this.tableDataOrderDetails2.forEach(element => {
                element.listaComprados.forEach( wow => {
                    wow.listaEnvasados.forEach( ylymynt => {
                        if( parseFloat(ylymynt.envased) > 0.0 ){
                            packaged_products.push(ylymynt);
                        }
                        
                    });
                });
            });
            this.tableDataOrderDetails2.forEach(element => {
                element.listaComprados.forEach( wow => {
                    wow.listaMermados.forEach( ylymynt => {
                        if( parseFloat(ylymynt.mermed) > 0.0 ){
                            mermed_products.push(ylymynt);
                        }
                    });
                });
            });
            var vm = this;
            this.axios({
                url: 'inventory/packaged_products',
                method: 'POST',
                data: { 
                    packaged_products: packaged_products.map(e => ({
                        cantidad: parseFloat(e.envased),
                        producto: {id: e.id},
                        sku: e.generated_sku,
                        unidad: {id: e.unit.id},
                        vencimiento: e.expires_at,
                        supplier_id: e.supplier_id
                        //price: e.price
                    })),
                    merma:  mermed_products.map( e => ({
                        cantidad: parseFloat(e.mermed),
                        producto: {id: e.id},
                        comment: e.comment,
                        unidad: {id: e.unit.id},
                        supplier_id: e.supplier_id
                    })),
                    purchase_order_id: this.selected[0].id
                },
            }).then( response => {
                vm.$bvToast.toast('Se han creado exitosamente '+packaged_products.length+' productos envasados', {
                    title: `Notificación`,
                    variant: 'success',
                    solid: true,
                    toaster: 'b-toaster-bottom-center'
                });
                vm.mostrar = 0;
                vm.fetchDataPurchaseOrders();
            }).catch( error => {
                console.log(error);
            });

        }
    },
    mounted()
    {
        this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Ordenes de producción", route: "/compras/envasar" },
        ]);
        this.fetchDataPurchaseOrders();
    }
}
</script>