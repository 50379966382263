var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-dialog',{attrs:{"max-width":"1000px"},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('v-card',[_c('v-card-title',[_c('span',[_vm._v(_vm._s(_vm.title)+" | "+_vm._s(_vm.totalEnvasado)+" "+_vm._s(_vm.tableData[0] ? _vm.tableData[0].unit.name : '')+"/"+_vm._s(_vm.cantidadComprada)+" "+_vm._s(_vm.tableData[0] ? _vm.tableData[0].unit.name: ''))])]),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1 datatable",attrs:{"headers":_vm.headers,"items":_vm.tableData,"items-per-page":5,"no-results-text":"No hay resultados disponibles","no-data-text":"No hay datos disponibles","hide-default-footer":true,"disable-pagination":""},scopedSlots:_vm._u([{key:"item.envased",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},[_c('v-text-field',{attrs:{"type":"number","min":"0","step":"0.05","required":"","suffix":item.unit.name,"disabled":true},model:{value:(item.envased),callback:function ($$v) {_vm.$set(item, "envased", $$v)},expression:"item.envased"}})],1)]}},{key:"item.generated_sku",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},[_c('v-text-field',{ref:'inputEan'+item.idref,attrs:{"required":"","rules":_vm.reglasSku,"disabled":_vm.loading},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.consultaAjax(item)}},model:{value:(item.generated_sku),callback:function ($$v) {_vm.$set(item, "generated_sku", $$v)},expression:"item.generated_sku"}})],1)]}},{key:"item.expires_at",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},[_c('v-dialog',{ref:"dialog",attrs:{"return-value":item.expires_at,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(item, "expires_at", $event)},"update:return-value":function($event){return _vm.$set(item, "expires_at", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"prepend-icon":"event","readonly":"","disabled":true},model:{value:(item.expires_at),callback:function ($$v) {_vm.$set(item, "expires_at", $$v)},expression:"item.expires_at"}},on))]}}],null,true),model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[_c('v-date-picker',{attrs:{"scrollable":""},model:{value:(item.expires_at),callback:function ($$v) {_vm.$set(item, "expires_at", $$v)},expression:"item.expires_at"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modal = false}}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.dialog.save(item.expires_at)}}},[_vm._v("OK")])],1)],1)],1)]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},[(!!item.optional)?_c('v-btn',{attrs:{"text":"","icon":"","color":"red"},on:{"click":function($event){return _vm.eliminarFila(item)}}},[_c('v-icon',[_vm._v("mdi-close")])],1):_vm._e()],1)]}}])}),_c('br'),_c('v-btn',{on:{"click":_vm.agregar}},[_vm._v("Agregar 1")])],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.showDialog = false}}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"color":"success","text":"","disabled":_vm.tableData.some(function (e) { return ( !e.envased || !e.generated_sku || !e.expires_at ); }) && !!_vm.valid},on:{"click":_vm.sendForm}},[_vm._v(" Guardar ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }