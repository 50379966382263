<template>
<v-app>
    <v-dialog v-model="showDialog" max-width="800px">
        <v-card>
            <v-card-title>
                <span>{{title}} | {{totalMermado}} {{tableData[0] ? tableData[0].unit.name : ''}}</span>
            </v-card-title>
            <v-card-text>
                
                <v-data-table
                    :headers="headers"
                    :items="tableData"
                    :items-per-page="5"
                    no-results-text="No hay resultados disponibles"
                    no-data-text="No hay datos disponibles"
                    class="elevation-1 datatable"
                    :hide-default-footer="true"
                    disable-pagination
                >
                    <template v-slot:item.mermed="{ item }" >
                        <td class="text-center">
                            <v-text-field
                                v-model="item.mermed"
                                type="number"
                                min="0"
                                step="0.05"
                                required
                                :suffix="item.unit.name"
                                :disabled="item.blocked"
                            ></v-text-field>
                        </td>
                    </template>
                    <template v-slot:item.comment="{ item }" >
                        <td class="text-center">
                            <v-text-field
                                v-model="item.comment"
                                required
                                
                                
                                :disabled="loading"
                            ></v-text-field>
                        </td>
                    </template>
                    <template v-slot:item.expires_at="{ item }" >
                        <td class="text-center">
                            <v-dialog
                                ref="dialog"
                                v-model="modal"
                                :return-value.sync="item.expires_at"
                                persistent
                                width="290px"
                            >
                                <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="item.expires_at"
                                    prepend-icon="event"
                                    readonly
                                    v-on="on"
                                ></v-text-field>
                                </template>
                                <v-date-picker v-model="item.expires_at" scrollable>
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" @click="modal = false">Cancelar</v-btn>
                                <v-btn text color="primary" @click="$refs.dialog.save(item.expires_at)">OK</v-btn>
                                </v-date-picker>
                            </v-dialog>
                        </td>
                    </template>
                    <template v-slot:item.action="{ item }" >
                        <td class="text-center">
                            <v-btn v-if="!!item.optional" @click="eliminarFila(item)" text icon color="red">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </td>
                    </template>
                </v-data-table>
                <br>
                <v-btn @click="agregar">Agregar 1</v-btn>
            </v-card-text>
            <v-card-actions>
                <v-btn
                    color="primary"
                    text
                    @click="showDialog = false"
                    >
                    Cancelar
                </v-btn>
                <v-btn
                    color="success"
                    text
                    :disabled="tableData.some(e => ( !e.mermed || !e.comment || !e.expires_at )) && !!valid"
                    @click="sendForm"
                    >
                    Guardar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</v-app>
</template>

<script>
export default {
    data(){
        return {
            valid: false,
            showDialog: false,
            title: '',
            headers: [
                {text: 'Cantidad merma', value: 'mermed' },
                {text: 'Comentario', value: 'comment' },
                //{text: 'Precio venta', value: 'price' },
                {text: 'Fecha vencimiento', value: 'expires_at' },
                {text: 'Proveedor', value: 'supplier_name'},
                {text: 'Accion', value: 'action'},

            ],
            tableData: [],
            modal: false,
            indexSku: undefined,
            indexProveedor: undefined,
            //reglasSku: [
            //    value => this.isDuplicado(value)
            //],
            //cantidadComprada: 0.0,
            loading: false,
        }
    },
    created(){
        this.$parent.$on('openDialogMermador', (data) => {
            this.title = data.title
            this.indexProveedor = data.indexProveedor;
            this.indexSku = data.indexSku;
            this.tableData = [];
            this.tableData = data.listaMermados.map(e => ({...e, supplier_id: data.supplier_id, supplier_name: data.supplier_name, blocked: false }));
            //this.cantidadComprada = data.cantidadComprada;
            this.showDialog = true;    
        });
    },
    methods: {
        /*
        consultaAjax(item)
        {
            this.loading = true;
            var vm = this;
            this.axios({
                url: 'inventory/preg_product_sku',
                method: 'POST',
                data: {
                    sku: item.generated_sku
                }
            }).then( response => {
                console.log(response.data);
                
                if(vm.isDuplicado(item.generated_sku))
                {
                    item.mermed = parseFloat(response.data.quantity);
                    item.blocked = true;
                }
                vm.loading = false;
            }).catch( error => {
                vm.loading = false;
            });
            console.log('se apreto el enter');
        },
        */
       /*
        isDuplicado(value)
        {
            let contador = 0;
            this.tableData.forEach( elem => {
                if( elem.mermed > 0.0 ){
                    if(elem.generated_sku == value){
                        contador++;
                    }
                }
            });
            return contador < 2 ? true : 'Sku repetido'
        },
        */
        agregar()
        {
            //let nuevo = {};
            this.tableData.push({
                mermed: 0.0,
                expires_at: new Date().toISOString().substr(0, 10),
                comment: '',
                id: this.tableData[0].id,
                unit: this.tableData[0].unit,
                supplier_id: this.tableData[0].supplier_id,
                supplier_name: this.tableData[0].supplier_name,
                optional: true,
            });
        },
        sendForm(){
            /*
            if( this.tableData.some( e => this.isDuplicado(e.generated_sku) == 'Sku repetido' ) ){
                this.$bvToast.toast('Debes corregir el problema: "Sku repetido"', {
                    title: `Error`,
                    variant: 'danger',
                    solid: true,
                    toaster: 'b-toaster-bottom-center'
                });
                return;
            }
            */
            console.log('sendForm');
            this.$emit('finish', {listaMermados: this.tableData, indexSku: this.indexSku, indexProveedor: this.indexProveedor });
           
            this.showDialog = false;
        },
        eliminarFila(item){
            const index = this.tableData.indexOf(item);
            this.tableData.splice(index,1);
        },
    },
    computed: {
        totalMermado()
        {   
            let acum = 0.0;
            this.tableData.forEach(e => {
                acum+= parseFloat(e.mermed);
            })
            return acum;
        },
    }
}
</script>